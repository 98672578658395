import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

const  Header = () => import ("@/components/Header")
const  Home = () => import('@/pages/home/index')
const  Details = () => import('@/pages/details/index')
const  Details3 = () => import('@/pages/details3/index')
const  Wangyueche = () => import('@/pages/wangyueche/index')

const  Icpedi = () => import('@/pages/icpedi/index')
const  Icp = () => import('@/pages/icpedi/icp')
const  Edi = () => import('@/pages/icpedi/edi')

const  Dengbaohegui = () => import('@/pages/dengbaohegui/index')
const  Dbhgo = () => import('@/pages/dengbaohegui/dbhgo')
const  Dbhge = () => import('@/pages/dengbaohegui/dbhge')
const  Security = () => import('@/pages/dengbaohegui/security')
const  Yitihua = () => import('@/pages/yitihua/index')
const  Fuhexingceping = () => import('@/pages/fuhexingceping/index')
const  Newnet = () => import('@/pages/newnet/index')
const  Yulun = () => import('@/pages/yulun/index')
const  Sjcj = () => import('@/pages/sjcj/index')
const  Pg = () => import('@/pages/pg/index')
const  Chepai = () => import('@/pages/chepai/index')
const  Sys = () => import('@/pages/sys/index')
const  Ziceping = () => import('@/pages/ziceping/index')
const  Zice = () => import('@/pages/zice/index')
const  Tongxin = () => import('@/pages/tongxin/index')
const  Introduction = () => import('@/pages/introduction/index')
const  Ruwangsafe = () => import('@/pages/ruwangsafe/index')
const  Chepaihandle = () => import('@/pages/chepaihandle/index')
const  Icpedihandle = () => import('@/pages/icpedihandle/index')
const  ArticleList = () => import('@/pages/articleList/index')
const  Yunsafe = () => import('@/pages/yunsafe/index')
const  Yunsafe1 = () => import('@/pages/yunsafe1/index')
const  Yunsafe2 = () => import('@/pages/yunsafe2/index')
const  Yunsafe3 = () => import('@/pages/yunsafe3/index')
const  Dengbaozhengming = () => import('@/pages/dengbaozhengming/index')
const  Dengbaozhenggai = () => import('@/pages/dengbaozhenggai/index')

const ConsultDetail = () =>import('@/pages/ConsultDetail/index')
const ConsultDetail1 = () =>import('@/pages/ConsultDetail1/index')
const ConsultDetail2 = () =>import('@/pages/ConsultDetail2/index')
const ConsultDetail3 = () =>import('@/pages/ConsultDetail3/index')
const Login = () =>import('@/pages/Login/index')
const Register = () =>import('@/pages/register/index')

const Case = () =>import('@/pages/case/index')
const Scene = () =>import('@/pages/scene/index')
const Dengbaomeal = () =>import('@/pages/dengbaomeal/index')
const Forget = () =>import('@/pages/forget/index')
const Dengbao2 = () =>import('@/pages/dengbao2/index')
const Dengbao3 = () =>import('@/pages/dengbao3/index')
const Firewall = () =>import('@/pages/firewall/index')
const Fort = () =>import('@/pages/fort/index')
const Log = () =>import('@/pages/log/index')
const SafeCenter = () =>import('@/pages/safeCenter/index')
const Outsource = () =>import('@/pages/outsource/index')
const Yunwei = () =>import('@/pages/yunwei/index')

const Search = () =>import('@/pages/search/index')
const App = () =>import('@/pages/app/index')
const Ability = () =>import('@/pages/app/ability')
const Assessment = () =>import('@/pages/app/assessment')
const App1 = () =>import('@/pages/app1/index')
const App2 = () =>import('@/pages/app2/index')
const App3 = () =>import('@/pages/app3/index')
const App4 = () =>import('@/pages/app4/index')
const App5 = () =>import('@/pages/app5/index')

const Hubei = () =>import('@/pages/record/hubei')
const Shanxi = () =>import('@/pages/record/shanxi')
const Jiangxi = () =>import('@/pages/record/jiangxi')
const Shanghai = () =>import('@/pages/record/shanghai')
const Tianjin = () =>import('@/pages/record/tianjin')
const Jiangsu = () =>import('@/pages/record/jiangsu')
const Zhejiang = () =>import('@/pages/record/zhejiang')
const Shenzhen = () =>import('@/pages/record/shenzhen')
const Beijing = () =>import('@/pages/record/beijing')
const Guangdong = () =>import('@/pages/record/guangdong')
const Car = () =>import('@/pages/record/car')

const Epiboly = () =>import('@/pages/services/epiboly')
const Inspection = () =>import('@/pages/services/inspection')
const Guarantee = () =>import('@/pages/services/guarantee')
const Tightening = () =>import('@/pages/services/tightening')
const Reinforce = () =>import('@/pages/services/reinforce')
const Monitor = () =>import('@/pages/services/monitor')
const Response = () =>import('@/pages/services/response')
const Operation = () =>import('@/pages/services/operation')
const Defense = () =>import('@/pages/services/defense')
const Consciousness = () =>import('@/pages/services/consciousness')
const Iso = () =>import('@/pages/services/iso')

const Appinfiltration = () =>import('@/pages/testing/appinfiltration')
const Infiltration = () =>import('@/pages/testing/infiltration')
const Inspect = () =>import('@/pages/testing/inspect')
const Scanning = () =>import('@/pages/testing/scanning')
const Webinfiltration = () =>import('@/pages/testing/webinfiltration')
const Webinspect = () =>import('@/pages/testing/webinspect')
const Webscanning = () =>import('@/pages/testing/webscanning')

const Layout = () =>import("@/components/layout/index.vue")
const Order = () =>import("@/views/order/index.vue")
const UserInfo = () =>import("@/views/userInfo/index.vue")
const UserInfoEdit = () =>import("@/views/userInfo/components/edit.vue")
const OrderDetails = () =>import("@/views/order/components/details.vue")
const Test = () =>import("@/pages/Test/test1.vue")
const Test3 = () =>import("@/pages/Test/test3.vue")
const product = () =>import("@/pages/details/product.vue")
const Header20210612 = () =>import("@/pages/comm/Header20210612")

const HomeNew = () =>import("@/pages/home/index20210701")
const MainNew = () =>import("@/pages/comm/Main.vue")

const Audit = () =>import("@/pages/audit/audit.vue")

Vue.use(VueRouter)

const routes = [
  {path: '/login',name:'登录', meta: {title: '用户登录'},component:Login},
  {path: '/register',name:'注册', meta: {title: '用户注册'},component:Register},
  {path:"/forget", meta: {title: '忘记密码'}, component:Forget},
    {path:"/test", meta: {title: 'Test'}, component:Test},
    {path:"/test3", meta: {title: 'Test3'}, component:Test3},
    {path:"/product", meta: {title: 'product'}, component:product},
    {path:"/HeaderNew", meta: {title: 'Header20210612'}, component:Header20210612},
    {path:"/HomeNew", meta: {title: 'HomeNew'}, component:HomeNew},
    {path:"/MainNew", meta: {title: 'MainNew'}, component:MainNew},

  {path:'/', component:Header, children:[
          {path: '/',name:'首页', meta: {title: '万方安全-信息安全服务商-让网络更安全'},component:Home},
          {path:"/details", meta: {title: '网络安全等级保护测评服务'},component:Details},
          {path:"/details3", meta: {title: '网络安全等级保护测评服务'},component:Details3},
          {path:"/wangyueche", meta: {title: '网约车移动应用程序（APP）安全保障能力报告'},component:Wangyueche},

          {path:"/icpedi", meta: {title: 'ICP/EDI许可证第三方风险评估'},component:Icpedi},
          {path:"/icp", meta: {title: 'ICP/EDI许可证第三方风险评估'},component:Icp},
          {path:"/edi", meta: {title: 'ICP/EDI许可证第三方风险评估'},component:Edi},


          {path:"/dengbaohegui", meta: {title: '等保合规套餐'}, component:Dengbaohegui},
          {path:"/dbhgo", meta: {title: '等保合规套餐'}, component:Dbhgo},
          {path:"/dbhge", meta: {title: '等保合规套餐'}, component:Dbhge},
          {path:"/security", meta: {title: '等保合规套餐'}, component:Security},
          {path:"/yitihua", meta: {title: '等保一体化解决方案'}, component:Yitihua},
          {path:"/fuhexingceping", meta: {title: '网络安全符合性测评报告'}, component:Fuhexingceping},
          {path:"/newnet", meta: {title: '互联网新技术新业务风险评估'}, component:Newnet},
          {path:"/yulun", meta: {title: '具有舆论属性的风险评估'}, component:Yulun},
          {path:"/sjcj", meta: {title: '数据出境合规服务'}, component:Sjcj},
          {path:"/pg", meta: {title: '数据出境合规服务'}, component:Pg},
          {path:"/chepai", meta: {title: '网约车牌照办理'}, component:Chepai},
          {path:"/sys", meta: {title: '网站和系统信息安全风险评估服务'}, component:Sys},
          {path:"/ziceping", meta: {title: '自测评风险评估'}, component:Ziceping},
          {path:"/zice", meta: {title: '自测评风险评估'}, component:Zice},
          {path:"/tongxin", meta: {title: '通信网络单元定级备案'}, component:Tongxin},
          {path:"/introduction", meta: {title: '万方安全-信息安全服务商-让网络更安全'}, component:Introduction},
          {path:"/ruwangsafe", meta: {title: '入网安全评估'}, component:Ruwangsafe},
          {path:"/chepaihandle", meta: {title: '网约车牌照办理'}, component:Chepaihandle},
          {path:"/icpedihandle", meta: {title: 'ICP/EDI许可证办理'}, component:Icpedihandle},
          {path:"/articleList", meta: {title: '文章列表'}, component:ArticleList},
          {path:"/yunsafe", meta: {title: '云上业务安全无忧保障计划'}, component:Yunsafe},
          {path:"/yunsafe1", meta: {title: '云上业务安全无忧保障计划'}, component:Yunsafe1},
          {path:"/yunsafe2", meta: {title: '云上业务安全无忧保障计划'}, component:Yunsafe2},
          {path:"/yunsafe3", meta: {title: '云上业务安全无忧保障计划'}, component:Yunsafe3},

          {path:"/consultDetail",  meta: {title: '文章详情页'}, component:ConsultDetail},
          {path:"/consultDetail1", meta: {title: '文章详情页'}, component:ConsultDetail1},
          {path:"/consultDetail2", meta: {title: '文章详情页'}, component:ConsultDetail2},
          {path:"/consultDetail3", meta: {title: '文章详情页'}, component:ConsultDetail3},

          {path:"/case", meta: {title: '成功案例'}, component:Case},
          {path:"/scene", meta: {title: '业务应用场景'}, component:Scene},
          {path:"/dengbaozhengming", meta: {title: '网络安全等级保护备案证明'}, component:Dengbaozhengming},
          {path:"/dengbaozhenggai", meta: {title: '网络安全等级保护备案证明'}, component:Dengbaozhenggai},
          {path:"/dengbaomeal", meta: {title: '阿里云等保套餐'}, component:Dengbaomeal},
          {path:"/dengbao2", meta: {title: '云业务系统等级保护安全产品套餐（二级等保）'}, component:Dengbao2},
          {path:"/dengbao3", meta: {title: '云业务系统等级保护安全产品套餐（三级等保）'}, component:Dengbao3},
          {path:"/firewall", meta: {title: '云防火墙'}, component:Firewall},
          {path:"/fort", meta: {title: '云堡垒机'}, component:Fort},
          {path:"/log", meta: {title: '云日志审计'}, component:Log},
          {path:"/safeCenter", meta: {title: '云日志审计'}, component:SafeCenter},
          {path:"/outsource", meta: {title: '安全服务外包'}, component:Outsource},
          {path:"/yunwei", meta: {title: '安全服务外包'}, component:Yunwei},
          {path:"/search", meta: {title: '搜索'}, component:Search},
          {path:"/app", meta: {title: '成功案例'}, component:App},
          {path:"/app1", meta: {title: '成功案例'}, component:App1},
          {path:"/app2", meta: {title: '成功案例'}, component:App2},
          {path:"/app3", meta: {title: '成功案例'}, component:App3},
          {path:"/app4", meta: {title: '成功案例'}, component:App4},
          {path:"/app5", meta: {title: '成功案例'}, component:App5},
          
          {path:"/hubei",name:'hubei',meta: {title: '成功案例'}, component:Hubei},
          {path:"/shanxi",name:'shanxi',meta: {title: '成功案例'}, component:Shanxi},
          {path:"/jiangxi",name:'jiangxi',meta: {title: '成功案例'}, component:Jiangxi},
          {path:"/shanghai",name:'shanghai',meta: {title: '成功案例'}, component:Shanghai},
          {path:"/tianjin",name:'tianjin',meta: {title: '成功案例'}, component:Tianjin},
          {path:"/jiangsu",name:'jiangsu',meta: {title: '成功案例'}, component:Jiangsu},
          {path:"/zhejiang",name:'zhejiang',meta: {title: '成功案例'}, component:Zhejiang},
          {path:"/shenzhen",name:'shenzhen',meta: {title: '成功案例'}, component:Shenzhen},
          {path:"/beijing",name:'beijing',meta: {title: '成功案例'}, component:Beijing},
          {path:"/guangdong",name:'guangdong',meta: {title: '成功案例'}, component:Guangdong},
          {path:"/car",name:'car',meta: {title: '成功案例'}, component:Car},
          
          {path:"/ability",name:'ability',meta: {title: '成功案例'}, component:Ability},
          {path:"/assessment",name:'assessment',meta: {title: '成功案例'}, component:Assessment},
          
          {path:"/audit",name:'audit',meta: {title: '成功案例'}, component:Audit},
          
          {path:"/epiboly",name:'epiboly',meta: {title: '成功案例'}, component:Epiboly},
          {path:"/inspection",name:'inspection',meta: {title: '成功案例'}, component:Inspection},
          {path:"/tightening",name:'tightening',meta: {title: '成功案例'}, component:Tightening},
          {path:"/guarantee",name:'guarantee',meta: {title: '成功案例'}, component:Guarantee},
          {path:"/reinforce",name:'reinforce',meta: {title: '成功案例'}, component:Reinforce},
          {path:"/monitor",name:'monitor',meta: {title: '成功案例'}, component:Monitor},
          {path:"/response",name:'response',meta: {title: '成功案例'}, component:Response},
          {path:"/operation",name:'operation',meta: {title: '成功案例'}, component:Operation},
          {path:"/defense",name:'defense',meta: {title: '成功案例'}, component:Defense},
          {path:"/consciousness",name:'consciousness',meta: {title: '成功案例'}, component:Consciousness},
          {path:"/iso",name:'iso',meta: {title: '成功案例'}, component:Iso},

          {path:"/appinfiltration",name:'appinfiltration',meta: {title: '成功案例'}, component:Appinfiltration},
          {path:"/infiltration",name:'infiltration',meta: {title: '成功案例'}, component:Infiltration},
          {path:"/inspect",name:'inspect',meta: {title: '成功案例'}, component:Inspect},
          {path:"/scanning",name:'scanning',meta: {title: '成功案例'}, component:Scanning},
          {path:"/webinfiltration",name:'webinfiltration',meta: {title: '成功案例'}, component:Webinfiltration},
          {path:"/webinspect",name:'webinspect',meta: {title: '成功案例'}, component:Webinspect},
          {path:"/webscanning",name:'webscanning',meta: {title: '成功案例'}, component:Webscanning},
       ]
   },

    {path:'/user', component:Layout, children:[
        // 订单
        {path:'/user/order',name:'aa', meta: {title: '订单列表'}, component:Order},
        {path:'/user/orderdetails', meta: {title: '订单详情'}, component:OrderDetails},
        // 基础信息
        {path:'/user/userInfo', meta: {title: '基础信息'}, component:UserInfo},
        {path:'/user/userInfoEdit', meta: {title: '基础信息修改'}, component:UserInfoEdit},

      ]
  }


]


const router = new VueRouter({
  mode:'history',
  routes
})




router.beforeEach((to, from, next) => {
     // 让页面回到顶部
     document.documentElement.scrollTop = 0
     // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
    next()
 })

export default router;
