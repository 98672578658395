import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "../static/css/reset.css"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import MetaInfo from 'vue-meta-info'
import VueSimpleVerify from 'vue-simple-verify'
import '../node_modules/vue-simple-verify/dist/vue-simple-verify.css'
// 引入axios依赖
import axios from 'axios'
// 引入自定义axios.js
import "./axios.js"
//import './permission.js' // 路由拦截
import alert from '@/common/alert'




//引用全局
Vue.prototype.$axios = axios
Vue.use(MetaInfo)

Vue.use(ElementUI);

// Vue.use(alert);

Vue.config.productionTip = false

Vue.component('vue-simple-verify', VueSimpleVerify)

/* 路由发生变化修改页面title */
/*router.beforeEach((to, from, next) => {

  if (to.meta.title) {
      document.title = to.meta.title
  }
  next()
})*/


new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')





